
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddClientModal from "@/components/modals/forms/AddClientModal.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { array } from "yup/lib/locale";
import JwtService from "@/core/services/JwtService";
import { ActionsCust } from "@/store/enums/StoreCustEnums";

export default defineComponent({
  name: "apps-profile-listing",
  components: {
    ExportCustomerModal,
    AddClientModal,
  },
  methods: {
    // onClick()
    //   {
    //     (document.getElementById("individual_list_id")as HTMLInputElement) .style.display = 'none';
    //     (document.getElementById("company_list_id")as HTMLInputElement) .style.display = 'block';
    //     (document.getElementById("individual_id")as HTMLInputElement) .style.display = 'block';
    //     (document.getElementById("comp_id")as HTMLInputElement).style.display = 'none';
    //   },
    //   onCompanyClick()
    //   {
    //     (document.getElementById("company_list_id")as HTMLInputElement) .style.display = 'none';
    //     (document.getElementById("individual_list_id")as HTMLInputElement) .style.display = 'block';
    //     (document.getElementById("individual_id")as HTMLInputElement) .style.display = 'none';
    //     (document.getElementById("comp_id")as HTMLInputElement).style.display = 'block';
    //   }
  }, 

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const searchTearm = ref("");
    const searchTearm1 = ref("");
    //const listVisible = ref<boolean>(false);
    interface WICompanies {
      business_entity_id: number,
      business_entity_name: string;
      business_entity_legal_name: string;
      nature_of_business: string;
      registration_type: string;
      business_entity_pan: string;
      pan_verified_yn: string;
       active: {
        label: string;
        color: string;
      };
    }

    interface WIIndividual {
      contact_id: number,
      contact_name: string;
      contact_pan: string;
      contact_designation_name: string;
      pan_verified_yn: string;
      //  status: {
      //   label: string;
      //   color: string;
      // };
       active: {
        label: string;
        color: string;
      };
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
      page_select: 1
    };

    var paginationData1 = ref({});

    paginationData1.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      pageRange: [1],
      page_select: 1
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      paginationData.value['page_select'] = 1;
      getCompanyList(searchTearm.value);
    };

    const refreshData1 = () => {
      paginationData1.value["activePage"] = 1;
      getIndividualList(searchTearm1.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
      if(paginationData.value['totPage'] == page){
      paginationData.value['page_select'] = page
      }
      if(paginationData.value['activePage'] == 1){
      paginationData.value['page_select'] = page
      }
      
    };

    const changePageChange1 = (page) => {
      paginationData1.value["activePage"] = page;
      getIndividualList(searchTearm1.value);
      if(paginationData1.value['totPage'] == page){
      paginationData1.value['page_select'] = page
      }
      if(paginationData1.value['activePage'] == 1){
      paginationData1.value['page_select'] = page
      }
      
    };

    var tableData = ref<Array<WICompanies>>([]);
    var tableData1 = ref<Array<WIIndividual>>([]);

    const getCompanyList = async (data) => {
      loadingData.value = true;
      (document.getElementById("company_list_id")as HTMLInputElement) .style.display = 'none';
        (document.getElementById("individual_list_id")as HTMLInputElement) .style.display = 'block';
        (document.getElementById("individual_id")as HTMLInputElement) .style.display = 'none';
        (document.getElementById("comp_id")as HTMLInputElement).style.display = 'block';
      try {
        
        var values = {
           company_id: 0,
           search_term: data,
          page: parseInt(paginationData.value["activePage"]) || parseInt(paginationData.value['page_select']),
          records_per_page: parseInt(paginationData.value["perPage"]),
        };

        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_LIST, values)
          .then(({ data }) => {
            JwtService.saveUserData({
              rakesh_count: data.rakesh_count,
              nikhil_count: data.nikhil_count,
              mansi_count: data.mansi_count,
            });
            console.log(JwtService.getUserData());

            tableData.value = [];
            if(data != 0){
            // set pagination
            paginationData.value["total"] = data.total_records;
            paginationData.value["start"] = data.records_from;
            paginationData.value["end"] = data.records_upto;
            paginationData.value["activePage"] = data.page;
            paginationData.value["totPage"] = data.total_pages;
            paginationData.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData.value["pageRange"].push(index);
            }
            //endpagination
          console.log("paginationData.value")
          console.log(paginationData.value)
            var resultsM = ref<Array<WICompanies>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.result_list.length; j++) {
              // if (data.result_list[j]["company_verified_yn"]) {
              //   status_label = "Verified";
              //   status_color = "success";
              // } else {
              //   status_label = "Unverified";
              //   status_color = "danger";
              // }

              if (data.result_list[j]["active"]) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsM.value = Array({
                business_entity_id: data.result_list[j]["company_id"],
                business_entity_name: data.result_list[j]["company_name"],
                business_entity_legal_name: data.result_list[j]["company_legal_name"],
                nature_of_business: data.result_list[j]["company_nob_name"],
                registration_type: data.result_list[j]["company_business_type_name"],
                business_entity_pan: data.result_list[j]["company_pan"],
                pan_verified_yn: data.result_list[j]["pan_verified_yn"],
                active: {
                  label: active_label,
                  color: active_color,
                },
                // status: {
                //   label: status_label,
                //   color: status_color,
                // },
              });

              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            // tableData.value = [{
            //   company_id: 1,
            //   user: {
            //     avatar: "string;",
            //     name: "string;",
            //     group: "string;",
            //   },
            //   comp_type: "string;",
            //   status: {
            //     label: "string;",
            //     color: "string;",
            //   },
            //   active: {
            //     label: "string;",
            //     color: "string;,"
            //   },
            //   roc: "string,",
            //   nob: "string,",
            //   doi: "string,",
            //   pan: "string,"
            // }];

            loadingData.value = false;
            }
            if(data == 0){
              loadingData.value = false;
                console.log("EMPTY DATA")
                // set pagination
              paginationData.value['total'] = 0;        
              paginationData.value['start'] = 1
              paginationData.value['end'] = 1
              paginationData.value['activePage'] = 1;
              paginationData.value['totPage']  = 0
              paginationData.value['pageRange'] = [1]
              for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
                paginationData.value['pageRange'].push(index)
              }
              // paginationData.value['page_select'] = 0
              //endpagination
            }
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

     const getIndividualList = async (data) => {
      loadingData.value = true;
      (document.getElementById("individual_list_id")as HTMLInputElement).style.display = 'none';
        (document.getElementById("company_list_id")as HTMLInputElement).style.display = 'block';
        (document.getElementById("individual_id")as HTMLInputElement).style.display = 'block';
        (document.getElementById("comp_id")as HTMLInputElement).style.display = 'none';
        (document.getElementById("search_ind_id")as HTMLInputElement).style.display = 'block';
        (document.getElementById("search_comp_id")as HTMLInputElement).style.display = 'none';
      try {
        
        var values = {
           contact_id:0,
           search_term: data,
          page: parseInt(paginationData1.value["activePage"]) || parseInt(paginationData1.value['page_select']),
          records_per_page: parseInt(paginationData1.value["perPage"]),
        };

        await store
          .dispatch(Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_LIST, values)
          .then(({ data }) => {
            JwtService.saveUserData({
              rakesh_count: data.rakesh_count,
              nikhil_count: data.nikhil_count,
              mansi_count: data.mansi_count,
            });
            console.log(JwtService.getUserData());

            tableData1.value = [];
            if(data != 0){
            // set pagination
            paginationData1.value["total"] = data.total_records;
            paginationData1.value["start"] = data.records_from;
            paginationData1.value["end"] = data.records_upto;
            paginationData1.value["activePage"] = data.page;
            paginationData1.value["totPage"] = data.total_pages;
            paginationData1.value["pageRange"] = [];
            for (
              let index = data.bar_range_start;
              index < data.bar_range_end;
              index++
            ) {
              paginationData1.value["pageRange"].push(index);
            }
            //endpagination

            var resultsM = ref<Array<WIIndividual>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.result_list.length; j++) {
              if (data.result_list[j]["contact_verified_yn"]) {
                status_label = "Verified";
                status_color = "success";
              } else {
                status_label = "Unverified";
                status_color = "danger";
              }

              if (data.result_list[j]["active"]) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsM.value = Array({
                contact_id: data.result_list[j]["contact_id"],
                contact_name: data.result_list[j]["contact_name"],
                contact_designation_name: data.result_list[j]["contact_designation_name"],
                contact_pan: data.result_list[j]["contact_pan"],
                pan_verified_yn: data.result_list[j]["pan_verified_yn"],
                active: {
                  label: active_label,
                  color: active_color,
                },
                // status: {
                //   label: status_label,
                //   color: status_color,
                // },
              });

              tableData1.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            // tableData.value = [{
            //   company_id: 1,
            //   user: {
            //     avatar: "string;",
            //     name: "string;",
            //     group: "string;",
            //   },
            //   comp_type: "string;",
            //   status: {
            //     label: "string;",
            //     color: "string;",
            //   },
            //   active: {
            //     label: "string;",
            //     color: "string;,"
            //   },
            //   roc: "string,",
            //   nob: "string,",
            //   doi: "string,",
            //   pan: "string,"
            // }];

            loadingData.value = false;
            }
            if(data == 0){
              loadingData.value = false;
                console.log("EMPTY DATA")
                // set pagination
              paginationData1.value['total'] = 0;        
              paginationData1.value['start'] = 1
              paginationData1.value['end'] = 1
              paginationData1.value['activePage'] = 1;
              paginationData1.value['totPage']  = 0
              paginationData1.value['pageRange'] = [1]
              for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
                paginationData1.value['pageRange'].push(index)
              }
              // paginationData1.value['page_select'] = 0
              //endpagination
            }
          })
          .catch(({ response }) => {
            tableData1.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WICompanies>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      // await getIndividualList(searchTearm1.value);
      setCurrentPageBreadcrumbs("User Profile", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].business_entity_id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
    };

    const search1 = ref<string>("");
    const searchItems1 = () => {
      searchTearm1.value = search1.value;
      getIndividualList(search1.value);
      console.log(search1.value);
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      tableData1,
      deleteCustomer,
      search,
      search1,
      searchItems,
      searchItems1,
      checkedCompany,
      deleteFewCustomers,
      count,
      getCompanyList,
      getIndividualList,
      paginationData,
      changePageChange,
      refreshData,
      paginationData1,
      changePageChange1,
      refreshData1,
      loadingData,
      //listVisible,
    };
  },
});
